import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/nodiscrimination/savinglives_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/nodiscrimination/savinglives_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/nodiscrimination/savinglives_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/nodiscrimination/savinglives_card_img4@2x.jpg';
import CardImg5 from '@/assets/img/nodiscrimination/savinglives_card_img5@2x.jpg';
import WedoIco1 from '@/assets/img/nodiscrimination/savinglives_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/nodiscrimination/savinglives_obj1_2@2x.png';
import WedoIco3 from '@/assets/img/nodiscrimination/savinglives_obj1_3@2x.png';
import WedoIco4 from '@/assets/img/nodiscrimination/savinglives_obj1_4@2x.png';
import WedoIco5 from '@/assets/img/nodiscrimination/savinglives_obj1_5@2x.png';
import WedoIco6 from '@/assets/img/nodiscrimination/savinglives_obj1_6@2x.png';
import WedoIco31 from '@/assets/img/nodiscrimination/savinglives_obj2020_1@2x.png';
import WedoIco32 from '@/assets/img/nodiscrimination/savinglives_obj2020_2@2x.png';
import WedoIco33 from '@/assets/img/nodiscrimination/savinglives_obj2020_3@2x.png';
import WedoIco34 from '@/assets/img/nodiscrimination/savinglives_obj2020_4@2x.png';
import WedoIco35 from '@/assets/img/nodiscrimination/savinglives_obj2020_5@2x.png';
import WedoIco36 from '@/assets/img/nodiscrimination/savinglives_obj2020_6@2x.png';
import WedoIco7 from '@/assets/img/nodiscrimination/savinglives_obj2_1@2x.png';
import WedoIco8 from '@/assets/img/nodiscrimination/savinglives_obj2_2@2x.png';
import WedoIco9 from '@/assets/img/nodiscrimination/savinglives_obj2_3@2x.png';
import WedoIco10 from '@/assets/img/nodiscrimination/savinglives_obj2_4@2x.png';
import WedoIco11 from '@/assets/img/nodiscrimination/savinglives_obj2_5@2x.png';
import SavinglivesTargetObj from '@/assets/img/nodiscrimination/savinglives_sec2_obj2@2x.png';
import SavinglivesTargetObjM from '@/assets/img/nodiscrimination/savinglives_sec2_obj2M@2x.png';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import ProcessList from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `보건`,
    dec: `유니세프의 산모·신생아·청소년 보건 프로그램은 통합적인 기초 보건 시스템을 강화하고, 필수적인 산모·신생아 보건 서비스를 통해 예방할 수 있는 사망을 줄입니다. 또한 질병으로부터 보호하고 치료하며, 모든 어린이가 예방 가능한 질병으로부터 완전히 보호하는 데 초점을 맞춥니다. 유니세프 보건 프로그램은 어린이 발달, 장애 어린이, 알려지지 않은 질병, 정신 건강 서비스, 인유두종 바이러스 백신, 임산부 보호, 환경 오염에 의한 질병, 청소년 산모 관리도 다룹니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `예방접종`,
    dec: `예방접종은 어린이의 생명을 구하는 가장 효과적인 방법입니다. 유니세프는 단 한 명의 어린이도 빠짐없이 예방접종을 할 수 있도록 아무리 외지고 험한 곳이더라도 달려갑니다. 이를 위해 드론 등 새로운 방법을 도입하기도 합니다. 또한, 백신을 적정 온도에서 조달하기 위해 태양광 발전, 모바일 기술, 원격 측정기 등을 활용한 콜드체인을 이용합니다. 게다가 유니세프는 세계 최대의 백신 구매 기관입니다. 유니세프는 이러한 구매력으로 가장 좋은 가격을 협상해 더 많은 생명을 살립니다. 진단 및 보건 기술 발전에도 투자합니다. 이를 통해 소아마비, 홍역, 파상풍 등 어린이 생명을 위협하는 수많은 질병이 사라지고 있습니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `영양`,
    dec: `유니세프는 임신 기간부터 생후 1,000일이 되는 때까지 필수 영양소를 충분히 섭취할 수 있도록 도와줍니다. 특히 이 시기 어린이는 환경에 예민하고, 다른 사람의 보살핌에 의존하기 때문에 체계적인 관리가 필요합니다. 나이를 기반으로 키와 몸무게, 팔 둘레를 측정하여 영양실조 여부를 확인합니다. <br/> 1) 성장과 발달에 있어 가장 중요한 기간 장기간 영양 결핍이 이뤄진 ‘발육부진’, 2) 나이에 비해 몸무게가 기준치 이하인 ‘저체중’, 3) 키보다 몸무게가 기준치 이하인 ‘체력 저하’, 4) 나이에 비해 몸무게가 기준치 이상인 ‘비만’, 5) 출생 시 저체중으로 구분해 관리합니다.<br/>유니세프는 특히 모성 영양, 건강, 유아와 어린이의 적절한 수유, 보살핌 행위를 지원하는 정책과 프로그램에 집중합니다.`,
    img: CardImg3,
  },
  {
    id: 4,
    title: `HIV*/AIDS`,
    dec: `유니세프는 모자감염 예방과 조기 치료에 중점을 두고 에이즈 퇴치 사업을 펼칩니다. 90%의 사람들이 HIV/AIDS 바이러스에 대해 제대로 알 수 있도록 교육하고, 감염 여부를 확인할 수 있도록 에이즈 감염 테스트 키트를 보급합니다. 에이즈 진단 환자 중 90%가 치료받게 하며, 에이즈 모자 감염을 막기 위해 깨끗하고 안전한 출산 환경을 유지하도록 합니다. 또한 90% 사람들이 에이즈를 예방하도록 항레트로바이러스제(ARV)를 보급하고, 정부가 어린이용 치료제 개발 및 보급에 힘쓸 수 있도록 협력합니다.`,
    tip: `* HIV(Human Immunode ciency Virus): 발병 시 에이즈(AIDS)로 진행되는 인간 면역 체계를 파괴하는 레트로바이러스`,
    img: CardImg4,
  },
  {
    id: 5,
    title: `영유아 발달`,
    dec: `유니세프는 모든 어린이가 인생을 행복하게 시작할 수 있도록 영양, 보건, 교육, 사회 보호, 부모 교육 및 지원 등 사업을 펼칩니다. 가난, 분쟁, 영양 부족, 오염된 환경 등으로 인해 영유아 시기의 발달 기회를 놓치지 않도록 취약계층을 지원합니다.  또한, 영유아기 두뇌 성장에 가장 중요한 생후 1년 동안 부모가 자녀에게 양질의 영양 섭취를 돕고 학습 발달 기회를 제공할 수 있도록, 모든 국가가 1) 2년간 예비 초등 교육, 2) 유급 모유수유 휴가 6주, 3) 유급 출산 휴가 6개월, 4) 유급 육아휴직 4주를 할 수 있도록 각국 정부와 협력합니다.`,
    img: CardImg5,
  },
];

const wedo = [  
  {
    id: 1,
    tit: `2019년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco7,
        desc: [
          {
            dec: `<strong>4,000만 명</strong> 이상 어린이에게 홍역 예방 접종`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco8,
        desc: [
          {
            dec: `중증 영양실조에 걸린 어린이 약 <strong>500만 명</strong> 치료`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco9,
        desc: [
          {
            dec: `어린이 <strong>2억 5,000만 명</strong> 비타민 A 등 필수미량영양소 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco10,
        desc: [
          {
            dec: `어린이 <strong>2,200만 명</strong> 에이즈 감염 테스트 키트 시행`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco11,
        desc: [
          {
            dec: `아기 <strong>2,800만 명</strong>이 깨끗한 환경에서 태어날 수 있도록 지원`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `아기 <strong>3,050만 명</strong>이 깨끗한 환경에서 태어날 수 있도록 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `긴급구호 상황에 있는 어린이 <strong>1,700만 명</strong>에게 홍역 예방접종`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `폐렴 의심 어린이 <strong>870만 명</strong>에게 항생제 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco34,
        desc: [
          {
            dec: `어린이 <strong>2,470만 명</strong> 에이즈 감염 테스트 키트 시행`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco35,
        desc: [
          {
            dec: `약 <strong>2억 4,400만 명</strong>에게 영양실조 예방 서비스 지원`,
          },
        ],
      },
      {
        id: 6,
        img: WedoIco36,
        desc: [
          {
            dec: `긴급구호 상황에 있는 어린이 <strong>280만 명</strong>에게 영유아 발달 프로그램 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `유니세프가 지원한 의료시설에서 신생아 <strong>3,890만 명</strong> 출생`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco10,
        desc: [
          {
            dec: `유니세프가 지원한 보건 인력이 출산 과정에 참여한 비율이 <strong>50개</strong> 중점 국가에서 <strong>80%</strong>로 증가`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `취약 국가 <strong>25개국</strong>에서 폐렴 의심 어린이 <strong>875 만 명</strong>에게 항생제 전달`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco35,
        desc: [
          {
            dec: `어린이 <strong>약 3억 3,600만 명</strong>에게 영양실조 예방 프로그램 지원 (2020년 대비 <strong>38%</strong> 증가)`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco32,
        desc: [
          {
            dec: `청소년 <strong>6,740만 명</strong> 빈혈 및 기타 영양실조 예방`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco8,
        desc: [
          {
            dec: `5세 미만 어린이<br /><strong>3억 5,600만 명</strong>에게 영양실조 <br />예방 프로그램 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco9,
        desc: [
          {
            dec: `심각한 영양실조에 걸린 5세 미만 어린이 <strong>730만 명</strong> 치료`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco32,
        desc: [
          {
            dec: `어린이 <strong>7,790만 명</strong>에게 홍역 예방접종, <strong>143개국</strong>에 코로나19 백신 서비스 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco35,
        desc: [
          {
            dec: `<strong>67개국</strong>에 정신건강 서비스 지원`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco10,
        desc: [
          {
            dec: `어린이 <strong>1억 3,290만 명</strong> 홍역 예방접종`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco9,
        desc: [
          {
            dec: `어린이 <strong>4억 명</strong> 소아마비 예방접종`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco32,
        desc: [
          {
            dec: `<strong>105개국</strong>에 말라리아 백신 포함<br/><strong>총 27억 9,000만 회분 백신</strong> 지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco8,
        desc: [
          {
            dec: `<strong>15개국</strong> 중증 영양실조 어린이 <strong>600만 명</strong> 치료`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;
const SectionWedo2 = styled(SectionWedo)`
  .accordion-body {
    padding-bottom: 0;
  }
  .process-ol {
    .list-item {
      margin: 32px 0;
    }
  }
  ${breakpoint(`mobile`)} {
    .process-ol {
      justify-content: flex-start;
      .list-item {
        margin: 32px 0 !important;
      }
    }
  }
`;

const SavingLives: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST01'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A001'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="생존과 발달"
      description="for every child, health"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 건강하고 튼튼하게 자랄{` `}
                  <br className="small-hide" />
                  권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 모든 어린이가 성장하는 데 필요한 모든 영양소를 골고루
                섭취하고 예방 접종을 받으며 저렴하고 질 좋은 의료 서비스에 쉽게
                접근할 수 있도록 합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="yANVGB09xPY" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2022-2025 <br />
                  유니세프 중점 사업 목표
                </Tit>
              </h2>
              <ul className="blit">
                <li>
                  전 세계 신생아, 어린이 및 청소년 사망률 <strong>감소</strong>
                </li>
                <li>
                  전 세계 어린이 및 청소년을 위한 건강, 발달, 삶의 질{' '}
                  <strong>개선</strong>
                </li>
                <li>
                  영양실조로 발육 부진을 겪는 5세 미만 어린이{' '}
                  <strong>감소</strong>
                </li>
              </ul>
            </div>
            <Image
              pcSrc={SavinglivesTargetObj}
              mobileSrc={SavinglivesTargetObjM}
            />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo2 className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                모든 어린이의 생존과 발달을 위해,
                <br className="m-hide" /> 전 세계 파트너와 함께 유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList itemData={row.child} />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo2>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A001" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default SavingLives;
